"use client";

import useCanvasCursor from "@/utils/use-CanvasCursor";

const CanvasCursor = () => {
  useCanvasCursor();

  return (
    <canvas
      className="pointer-events-none fixed inset-0 hidden lg:flex"
      style={{ zIndex: "9999999" }}
      id="canvas"
    />
  );
};
export default CanvasCursor;
