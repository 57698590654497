"use client";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { navLinks } from "@/constants";
import { usePathname } from "next/navigation";
const Navbar = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const [scrolled, setScrolled] = useState(true);
  let lastScrollTop = 0; // Track the previous scroll position

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if(scrollTop > 200){
      if (scrollTop > lastScrollTop) {
        // Scrolling down
        setScrolled(false);
      } else {
        // Scrolling up
        setScrolled(true);
      }
    }else {
      // Always show navbar within the top 100px
      setScrolled(true);
    }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative values
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`paddingX  w-full flex items-center py-4 transition-all fixed ease-in-out duration-700 bg-primary  top-0  z-20 ${
        !scrolled ? "hidden" : "static"
      }`}
    >
      <div className="w-full flex justify-between items-center max-w-7xl mx-auto">
        <Link
          href="/"
          className="flex items-center gap-2"
          onClick={() => {
            setActive("");
            window.scrollTo(0, 0);
          }}
        >
          <Image
            width={100}
            height={100}
            src={`https://res.cloudinary.com/doe77lyoi/image/upload/v1725259718/zgewvztho784txiadb9v.jpg`}
            alt="logo"
            className="w-9 h-9 object-contain"
          />
          <p className="text-white text-[18px] font-bold cursor-pointer flex ">
            Nimesh &nbsp;
            <span className="sm:block hidden"> Regmi</span>
          </p>
        </Link>

        <ul className="list-none hidden sm:flex flex-row gap-10">
          {navLinks.map((nav, index) => (
            <li
              key={`${nav.id}-${index}-navbar-big`}
              className={`${
                active === nav.title ? "text-white" : "text-secondary"
              } hover:text-white group text-[18px] font-medium cursor-pointer`}
              onClick={() => setActive(nav.title)}
            >
              <Link href={`/${nav.idStat ? "#" : ""}${nav.id}`}>{nav.title}</Link>
              <div
                className={`${
                  active === nav.title ? "w-full" : " w-0"
                } group-hover:w-full h-1 bg-white rounded-lg transition-all ease-in-out duration-700`}
              />
            </li>
          ))}
        </ul>

        <div className="sm:hidden flex flex-1 justify-end items-center">
          <Image
            width={100}
            height={100}
            src={toggle ? "/close.svg" : "/menu.svg"}
            alt="menu"
            className="w-[28px] h-[28px] object-contain"
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col gap-4">
              {navLinks.map((nav, index) => (
                <li
                  key={`${nav.id}-${index}-navbar-small`}
                  className={`font-poppins font-medium cursor-pointer text-[16px] ${
                    active === nav.title ? "text-white" : "text-secondary"
                  }`}
                  onClick={() => {
                    setToggle(!toggle);
                    setActive(nav.title);
                  }}
                >
                  <Link href={`/${nav.idStat ? "#" : ""}${nav.id}`}>{nav.title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
