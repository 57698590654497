"use client";
import React, { Suspense } from "react";
const Preload = dynamic(
  () => import("@react-three/drei").then((mod) => mod.Preload),
  {
    ssr: false,
  }
);

const Canvas = dynamic(
  () => import("@react-three/fiber").then((mod) => mod.Canvas),
  {
    ssr: false,
  }
);

const OrbitControls = dynamic(
  () => import("@react-three/drei").then((mod) => mod.OrbitControls),
  {
    ssr: false,
  }
);

import dynamic from "next/dynamic";
// import CanvasLoader from "../Common/Loader";
const Computers = dynamic(() => import("./ComputeCanvas"), {
  ssr: false,
});


const ComputersCanvas = () => {

  return (
    <Canvas
      frameloop="demand"
      shadows
      dpr={[1, 1.5]}
      camera={{ position: [20, 3, 5], fov: 25 }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <Suspense fallback={"Loading..."}>
        <OrbitControls
          enableZoom={false}
          enablePan={false}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
        />
        <Computers isMobile={false} />
      </Suspense>

      <Preload all />
    </Canvas>
  );
};

export default ComputersCanvas;
