import(/* webpackMode: "eager", webpackExports: ["motion","div"] */ "/vercel/path0/node_modules/framer-motion/dist/es/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/canvas/Computers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/canvas/Earth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/canvas/StarBackground.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Common/AnimatedText.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Common/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Common/TitleWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ComponentWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Contact.tsx");
