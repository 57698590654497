"use client"; 

import React, { Suspense } from "react";
import dynamic from "next/dynamic";
// import Earth from "";

const Preload = dynamic(
  () => import("@react-three/drei").then((mod) => mod.Preload),
  {
    ssr: false,
  }
);

const Canvas = dynamic(
  () => import("@react-three/fiber").then((mod) => mod.Canvas),
  {
    ssr: false,
  }
);

const OrbitControls = dynamic(
  () => import("@react-three/drei").then((mod) => mod.OrbitControls),
  {
    ssr: false,
  }
);
const CanvasLoader = dynamic(() => import("../Common/Loading"), {
  ssr: false,
});
const Earth = dynamic(() => import("./EarthCanvas"), {
  ssr: false,
});

const EarthCanvas = () => {
  return (
    <Canvas
      shadows
      frameloop="demand"
      dpr={[1, 1.5]}
      gl={{ preserveDrawingBuffer: true }}
      camera={{
        fov: 50, // Adjust field of view
        near: 0.5, // Increase near clipping plane
        far: 100, // Decrease far clipping plane
        position: [-4, 3, 5], // Refine position
      }}
    >
      <Suspense fallback={<CanvasLoader />}>
        <OrbitControls
          autoRotate
          enablePan={false}
          enableZoom={false}
          maxPolarAngle={Math.PI / 2}
          minPolarAngle={Math.PI / 2}
        />
        <Earth />

        <Preload all />
      </Suspense>
    </Canvas>
  );
};

export default EarthCanvas;
