'use client'
import dynamic from "next/dynamic"

const HeroCanvaElement=dynamic(()=>import('./Hero'),{ssr:false})
const ContactCanvasElement=dynamic(()=>import('./Contact'),{ssr:false})
const StarsCanvas=dynamic(()=>import('@/components/canvas/StarBackground'),{ssr:false})
const ComputersCanvas=dynamic(()=>import('@/components/canvas/Computers'),{ssr:false})

export const HeroCanva=()=>{
    return <HeroCanvaElement/>
}
export const EarthCanvas=()=>{
    return <ContactCanvasElement/>
}
export  {StarsCanvas,ComputersCanvas};
